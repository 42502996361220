body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Anek Telugu", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #00160f;
}
h1 {
  font-family: "Anek Telugu", sans-serif;
}
p {
  font-family: "Open Sans", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 650px) {
  .username {
    display: none !important;
  }
}
